<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.EditTerms.Name.Main_Title')"
    :disableSaveButton="mBookingTerm.Version.length < 2"
    :onClickCancel="onClickCancel"
    :onClickSave="saveTerm"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <ui-base-input-field
        v-if="mBookingTerm"
        v-model="mBookingTerm.Version"
        :error="nameErrors"
        :required="true"
        :showLabel="false"
        fieldType="text"
        @keyup.enter="saveTerm"
        @input="$v.mBookingTerm.Version.$touch()"
        @blur="$v.mBookingTerm.Version.$touch()"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import termsProvider from '@/providers/terms'

import { required, minLength } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },

    bookingTerm: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mBookingTerm: null,
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Version: {
        required,
        minLength: minLength(2),
      },
    }
    return {
      mBookingTerm: output,
    }
  },

  computed: {
    nameErrors() {
      const errors = []
      if (this.mBookingTerm) {
        if (!this.$v.mBookingTerm.Version.$dirty) return errors
        !this.$v.mBookingTerm.Version.required &&
          errors.push(this.$t('Form.InputErrors.Required'))
        !this.$v.mBookingTerm.Version.minLength &&
          errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      }
      return errors
    },
  },

  created() {
    if (this.bookingTerm) {
      this.mBookingTerm = JSON.parse(JSON.stringify(this.bookingTerm))
    }
  },

  methods: {
    saveTerm() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          termsProvider.methods
            .updateBookingTerm(self.mBookingTerm)
            .then((response) => {
              if (response.status === 200) {
                self.isSavingSuccess = true
                self.$emit('termUpdated')

                let t = setTimeout(() => {
                  self.onClickCancel()
                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((e) => {
              // //console.log(error.response)
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>
